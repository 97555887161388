export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    children: [
      {
        title: "Quote Price",
        route: "quote-price",
        icon: "TagIcon",
      },
    ],
  },
  {
    title: "Order",
    route: "order",
    icon: "BoxIcon",
    children: [
      {
        title: "Outlet",
        route: "Outlet-tracker",
        icon: "BarChartIcon",
      },
      {
        title: "Singapore",
        route: "singapore-tracker",
        icon: "BarChartIcon",
      },
      {
        title: "Distributor",
        route: "distributor-tracker",
        icon: "BarChartIcon",
      },
      {
        title: "Ondemand",
        route: "ondemand-tracker",
        icon: "BarChartIcon",
      },
      {
        title: "DHL",
        route: "DHL-tracker",
        icon: "BarChartIcon",
      },
      {
        title: "In Warehouse",
        route: "in-warehouse",
        icon: "InboxIcon",
        resource: "InWarehouse",
        action: "manage",
      },
    ],
  },
  {
    title: "Create Delivery",
    route: "create-delivery",
    icon: "PackageIcon",
  },
  {
    title: "Users",
    route: "users",
    icon: "UserIcon",
    resource: "Users",
    action: "manage",
  },
  // {
  //   title: "Log Points",
  //   route: "log-points",
  //   icon: "TagIcon",
  //   resource: "LogPoints",
  //   action: "manage",
  // },
  {
    title: "Track & Trace",
    route: "track-and-trace",
    icon: "MapIcon",
  },
  {
    title: "Postal Coverage",
    route: "postal-coverage",
    icon: "MapPinIcon",
    children: [
      {
        title: "Core List",
        route: "core-list",
        icon: "ListIcon",
      },
      {
        title: "Outlet",
        route: "Outlet-postal",
        icon: "BriefcaseIcon",
      },
    ],
  },
  {
    title: "Price Checker",
    route: "price-checker",
    icon: "DollarSignIcon",
    children: [
      {
        title: "Distributor",
        route: "distributor-price",
        icon: "BarChartIcon",
        resource: "DistributorPrice",
        action: "manage",
      },
      {
        title: "Outlet",
        route: "Outlet-price",
        icon: "BarChartIcon",
        resource: "OutletPrice",
        action: "manage",
      },
    ],
  },
  {
    title: "Transaction",
    route: "transaction",
    icon: "MapPinIcon",
  },
  {
    title: "Report",
    route: "report",
    icon: "ClipboardIcon",
    children: [
      {
        title: "Distributor",
        route: "distributor",
        icon: "ListIcon",
      },
      {
        title: "Master List",
        route: "master-list",
        icon: "ListIcon",
      },
      {
        title: "Outlet",
        route: "Outlet-report",
        icon: "BriefcaseIcon",
      },
    ],
  },
  {
    title: "Admin Access",
    route: "admin-access",
    icon: "UserPlusIcon",
    children: [
      {
        title: "Users",
        route: "Outlet",
        icon: "UserIcon",
      },
      {
        title: "Authority",
        route: "authority",
        icon: "LockIcon",
      },
    ],
  },
  {
    title: "Drivers",
    route: "phone-book",
    icon: "TruckIcon",
  },
  {
    title: "Analytics",
    route: "analytics",
    icon: "BarChartIcon",
    children: [
      {
        title: "Shipment",
        route: "shipment-analytics",
        icon: "BarChart2Icon",
        resource: "ShipmentAnalytics",
        action: "manage",
      },
      {
        title: "Order",
        route: "order-analytics",
        icon: "BarChart2Icon",
        resource: "OrderAnalytics",
        action: "manage",
      },
      {
        title: "Delivery Status",
        route: "delivery-status-analytics",
        icon: "BarChart2Icon",
        resource: "DeliveryStatusAnalytics",
        action: "manage",
      },
      {
        title: "User",
        route: "user-analytics",
        icon: "BarChart2Icon",
        resource: "UserAnalytics",
        action: "manage",
      },
      {
        title: "Location",
        route: "location-analytics",
        icon: "BarChart2Icon",
        resource: "LocationAnalytics",
        action: "manage",
      },
    ],
  },
  {
    title: "Map",
    route: "map",
    icon: "MapPinIcon",
    resource: "Map",
    action: "manage",
  },
];
